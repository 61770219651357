import * as React from 'react';
import XeroLogo from '../../../public/svg/xero.svg';

export interface IProps {
  accountingPackageConnected: boolean;
  accountingPackageEmail: string;
  handleDisconnect: () => void;
  handleContact: () => void;
}

function AccountingPackageConnection({ accountingPackageConnected, accountingPackageEmail, handleDisconnect, handleContact }:IProps) {
  return (
    <>
    <label className="heading-2">accounting package</label>
    {accountingPackageConnected ? (
      <div className="mt-2">
        <div className="flex items-start">
          <XeroLogo width="3.5em" />
          <div>
            <span className="bg-blue-light text-blue rounded p-1 font-extrabold text-4xs uppercase">
              connected
            </span>
            <p className="text-navy text-sm mt-1">
              {accountingPackageEmail}
            </p>
          </div>
        </div>
        <button
          onClick={handleDisconnect}
          className="border border-black border-1 border-solid text-xs w-full rounded-lg p-3"
        >
          Disconnect from Xero
        </button>
      </div>
    ) : (
      <div className="mt-2">
        <p className="mb-2 mb-8 text-navy text-sm">
          Contact the Surgical Partners team to connect to your accounting
          package
        </p>
        <button
          onClick={handleContact}
          className="bg-tertiary text-xs w-full text-white rounded-lg p-3 font-extrabold"
        >
          Contact Surgical Partners
        </button>
      </div>
    )}
    </>
  )
};

export default AccountingPackageConnection;
