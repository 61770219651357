import axios from 'axios';
import qs from 'qs';
import { ILoader, IUser } from '../ISource';

import client from '../../lib/api/client';

const CancelToken = axios.CancelToken;

interface FetchParams {
  clinicId: null;
}

export default class AccountLoader implements ILoader<IUser> {
  private canceller: any;

  public cancel() {
    if (this.canceller) {
      this.canceller.cancel();
    }
  }

  public fetch(): Promise<IUser> {
    this.canceller = CancelToken.source();
    const url = `/api/account`;

    return client
      .get(url, { cancelToken: this.canceller.cancelToken })
      .then((res) => {
        return res.data;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request cancelled', thrown.message);
        } else {
          console.error('ERROR:', thrown.message);
        }

        return null;
      });
  }
}
