import * as React from 'react'

interface IProps {
  message: string
}

interface IState {
  show: boolean
}

export default class Help extends React.Component<IProps, IState> {

  public state = { show: false};

  public handleClick = () => {
    this.setState(prevState => ({ show: !prevState.show }));
  }

  public render() {
    return (
      <React.Fragment>
        <div onClick={this.handleClick} className="help-tip
        inline-block
        font-bold
        rounded-full
        text-center
        bg-grey-dark
        text-white
        h-6
        w-6
        leading-normal">?</div>
        <div className="relative flex justify-center">
          { 
            this.state.show ? 
            <p className="help-info
            bg-navy
            text-white
            w-1/2
            leading-loose
            absolute
            font-semibold
            text-2xs
            p-4
            rounded">{this.props.message}</p> :
            null
          }
        </div>
      </React.Fragment>
    );
  }
}