import axios from 'axios';
import { ILoader } from '../ISource';

import { IStatement } from '../../Earnings/StatementModal';
import client from '../../lib/api/client';

const CancelToken = axios.CancelToken;

interface IFetchParams {
  statementId: string;
  clinicId: string;
}

export default class StatementLoader implements ILoader<IStatement> {
  private canceller: any;

  public cancel() {
    if (this.canceller) {
      this.canceller.cancel();
    }
  }

  public fetch({ statementId, clinicId }: IFetchParams): Promise<IStatement> {
    this.canceller = CancelToken.source();

    const url = `/api/statements?statementId=${statementId}&clinicId=${clinicId}`;

    return client
      .get(url, { cancelToken: this.canceller.cancelToken })
      .then((res) => {
        return res.data;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request cancelled', thrown.message);
        } else {
          console.error('ERROR:', thrown.message);
        }

        return null;
      });
  }
}
