import moment from 'moment';

import {
  IBillingsApiData,
  IEarningsApiData,
  IItemCodesApiData,
  ISource,
} from './ISource';

import AccountLoader from './loaders/AccountLoader';
import StatementLoader from './loaders/StatementLoader';

export default class FakeDoctorHubSource implements ISource {
  public billings = {
    cancel() {
      return;
    },

    fetch(query: any): Promise<IBillingsApiData> {
      const billingsData: IBillingsApiData = {};

      const fromDate = query.fromDate;
      const toDate = query.toDate;

      const toDateMoment = moment(toDate, 'YYYY-MM-DD');
      const fromDateMoment = fromDate
        ? moment(fromDate, 'YYYY-MM-DD')
        : toDateMoment.clone().subtract(14, 'day');

      for (
        let date = fromDateMoment.clone();
        !date.isSame(toDateMoment, 'day');
        date = date.clone().add(1, 'day')
      ) {
        const myBillings = Math.floor(Math.random() * 101);
        const othersBillings = Math.floor(Math.random() * 101);
        const dateAsString = date.format('YYYY-MM-DD');

        billingsData[dateAsString] = {
          myBillings,
          othersBillings,
        };
      }

      return Promise.resolve(billingsData);
    },
  };
  public itemCodes = {
    cancel() {
      return;
    },

    fetch(): Promise<IItemCodesApiData> {
      const itemCodesData: IItemCodesApiData = {
        total: 100,
        items: [
          {
            code:
              'Item Code 1 extra long nameeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
            value: 50,
          },
          {
            code: 'Item Code 2',
            value: 4,
          },
          {
            code: 'Item Code 3',
            value: 8,
          },
          {
            code: 'Item Code 4',
            value: 12,
          },
          {
            code: 'Item Code 5',
            value: 6,
          },
          {
            code: 'Item Code 6',
            value: 2,
          },
          {
            code: 'Item Code 7',
            value: 3,
          },
          {
            code: 'Item Code 8',
            value: 5,
          },
          {
            code: 'Item Code 9',
            value: 9,
          },
          {
            code: 'Item Code 10',
            value: 1,
          },
        ],
      };

      return Promise.resolve(itemCodesData);
    },
  };
  public earnings = {
    cancel() {
      return;
    },

    fetch(): Promise<IEarningsApiData> {
      return new Promise((resolve) => {
        resolve();
      });
    },
  };
  public account = new AccountLoader();
  public statements = new StatementLoader();
}
