export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const setAccessToken = (token:any) => {
  localStorage.setItem('accessToken', token);
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

export const setRefreshToken = (token:any) => {
  localStorage.setItem('refreshToken', token);
};

export const destroyTokens = () => {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('clinicId');
};
