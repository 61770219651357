import React, { useEffect, useRef, useState } from 'react';

export interface IProps {
  options: Array<{
    text: string;
    value: string;
  }>;
  value: string | null;
  placeHolderText?: string;
  onChange: (value: string) => void;
}

const Dropdown = ({ options, onChange, value, placeHolderText }: IProps) => {
  const node = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const handleClick = (e: MouseEvent) => {
    if (e.target instanceof Node) {
      if (node.current && node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setOpen(false);
    }
  };

  const handleChange = (value: string) => {
    setOpen(false);
    onChange(value);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const selectedOption = options.find((o) => o.value === value);

  const placeholder = placeHolderText || 'Select Option';

  return (
    <div ref={node}>
      <div
        className="text-center text-2xs"
        onClick={(e) => setOpen(!open)}
        style={{ color: '#3C3E52' }}
      >
        {selectedOption ? selectedOption.text : placeholder} &#x2304;
      </div>
      {open && (
        <ul className="list-reset pin-l pin-r mx-auto w-48 absolute bg-white shadow-md rounded z-20">
          {options.map((opt) => (
            <li
              key={opt.value}
              className="cursor-pointer text-center pt-4 pb-4 pl-10 pr-10 text-sm"
              onClick={() => handleChange(opt.value)}
            >
              {opt.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
