import { History } from 'history';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SPLogo from '../../public/svg/splogo.svg';
import Header from './components/Header';
import { ILoginResult, login } from './lib/auth';
import Whitelabelling from './lib/Whitelabelling';
import { ISource } from './source/ISource';

interface ILoginState {
  emailAddress: string;
  password: string;
  showInvalidEmailError: boolean;
  showLoginFailedError: boolean;
  loginFailedError?: string | null;
}

interface ILoginProps extends RouteComponentProps {
  history: History;
  organisationLogo?: string;
  trackUser: (userId: string) => void,
  source: ISource
}

// Declare a global URL which is set earlier in the <head>
declare const LOGO_URL: string;

class Login extends React.Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.validate = this.validate.bind(this);

    this.state = {
      emailAddress: '',
      password: '',
      showInvalidEmailError: false,
      showLoginFailedError: false,
    };
  }

  public handleSubmit(event: any) {
    const { dispatch } = this.context;
    event.preventDefault();

    const { emailAddress, password } = this.state;

    this.setState({
      loginFailedError: null,
      showLoginFailedError: false,
      showInvalidEmailError: false,
    });
    const history = this.props.history;

    return login(emailAddress, password)
      .then((result) => {
        if (result.success) {

          return this.props.source.account.fetch().then((user) => {
            this.props.trackUser(user.id);
            history.push('/');
            return;
          });
        }

        this.setState({
          showLoginFailedError: true,
          loginFailedError: result.message,
        });
      })
      .then(Whitelabelling.loadCss);
  }

  public onEmailChange(e: any) {
    this.setState({
      emailAddress: e.target.value,
    });
  }

  public onPasswordChange(e: any) {
    this.setState({
      password: e.target.value,
    });
  }

  public validate() {
    const { emailAddress } = this.state;
    this.setState({
      showInvalidEmailError: /^\S+@\S+$/.test(emailAddress) === false,
    });
  }

  public render() {
    const { emailAddress, password, loginFailedError } = this.state;
    return (
      <div className="flex flex-col w-full h-full bg-grey-light">
        <Header>
          <div className="text-center">
              <span className="font-extrabold text-logo-text">Doctor</span>
              <span className="font-normal text-logo-text">Hub</span>
          </div>
        </Header>
        <div className="main-container flex flex-grow flex-col bg-grey-light justify-center items-center">
          <div className="w-4/5">
            {LOGO_URL ? <img
              src={LOGO_URL}
              className="mb-4 mx-auto block"
              style={{maxHeight: '200px'}}
            /> : null}
            <form className="login-form" onSubmit={this.handleSubmit}>
              <div>
                <input
                  value={emailAddress}
                  onBlur={this.validate}
                  onChange={this.onEmailChange}
                  className="login-input"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                />
              </div>
              {this.state.showInvalidEmailError && (
                <p className="errorText mb-4">Invalid email address</p>
              )}
              <div>
                <input
                  value={password}
                  onChange={this.onPasswordChange}
                  className="login-input"
                  type="password"
                  name="password"
                  placeholder="Password"
                />
              </div>
              {this.state.showLoginFailedError && (
                <p className="errorText mb-4">{loginFailedError}</p>
              )}
              <button
                disabled={this.state.showInvalidEmailError}
                onClick={this.handleSubmit}
                className="btn bg-primary w-100"
              >
                Login
              </button>
              <div className="mt-2 text-right">
                <a href="/privacy-policy">Privacy Policy</a>
              </div>
            </form>
          </div>
        </div>
        <div
          className="bg-white flex justify-center items-center"
          style={{ height: '4rem' }}
        >
          <div
            className="mr-2 text-font-semibold text-2xs tracking-wide"
            style={{ color: '#9D9EA8' }}
          >
            Powered by
          </div>
          <SPLogo height="2.5em" />
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
