import jwtDecode from 'jwt-decode';
import apiClient from './api/client';
import {
  destroyTokens,
  getAccessToken,
  setAccessToken,
  setRefreshToken,
} from './api/tokens';

export interface ILoginResult {
  success: boolean;
  message?: string;
  data?: any;
}
export interface IAccessToken {
  exp: number;
  data: any;
}

export const isLoggedIn = (): boolean => {
  const token = getAccessToken();

  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode<IAccessToken>(token);

  if (Date.now() / 1000 >= decodedToken.exp) {
    return false;
  }

  return true;
};

export const login = (
  email: string,
  password: string
): Promise<ILoginResult> => {
  return apiClient
    .post(`/api/auth/login`, {
      email,
      password,
    })
    .then((response) => {
      const { token, refreshToken } = response.data;
      setAccessToken(token);
      setRefreshToken(refreshToken);
      return {
        success: true,
      };
    })
    .catch((error) => {
      console.error(error);
      return {
        success: false,
        message: error.response.data.message,
      };
    });
};

export const logout = (): void => {
  destroyTokens();
};

export const disconnectFromXero = async (clinicId: string): Promise<any> => {
  return apiClient.post('/api/account/disconnect', { organisation: clinicId });
};
