import { getDefaultSettings } from 'http2';
import { AppState } from './App';

export const clinicHasChanged = (
  prevAppState: AppState,
  newAppState: AppState
): boolean => {
  if (newAppState.currentClinicId !== prevAppState.currentClinicId) {
    return true;
  }

  return false;
};
