import * as React from 'react';

export interface ISelectItem {
  label: string
}

interface IProps {
  items: ISelectItem[];
  handleChange: (index: number) => void;
}

interface IState {
  show: boolean;
  currentSelectedIndex: number;
}

export class Select extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      show: false,
      currentSelectedIndex: 0,
    };
  }

  public showOptions = () => {
    this.setState((prevState) => ({ show: !prevState.show }));
  };

  public updateDate(index = 0) {
    this.setState({ currentSelectedIndex: index, show: false });

    this.props.handleChange(index);
  }

  public selectOption = (index: number) => {
    this.updateDate(index);
  };

  public componentDidMount() {
    this.updateDate(this.state.currentSelectedIndex);
  }

  public render() {
    return (
      <div className="inline-block w-full">
        <div className="text-center inline-block text-2xs">
          <span>
            {'Display '}
            <span
              onClick={this.showOptions}
              className={`cursor-pointer font-bold ${
                this.state.show ? 'text-tertiary' : ''
              }`}
            >
              {this.props.items[this.state.currentSelectedIndex].label}
              &#x2304;
            </span>
          </span>
        </div>
        <div className="flex justify-center">
          {this.state.show ? (
            <ol className="list-reset w-48 absolute bg-white shadow-md rounded z-10">
              {this.props.items.map((item, i) => (
                <li
                  onClick={this.selectOption.bind(this, i)}
                  className="cursor-pointer pt-4 pb-4 pl-10 pr-10 text-sm"
                  key={i}
                >
                  {item.label}
                </li>
              ))}
            </ol>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Select;
