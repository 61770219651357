import * as React from 'react';
import { Link } from 'react-router-dom';
import { currencyFormat } from '../lib/utilities';
import { ITranslations } from '../types/ITranslations';

declare const TRANSLATIONS: ITranslations;

export interface IProps {
  earningsInfo: {
    statementId: string;
    netDistribution: number;
    fees: number;
    servicesIncome: number;
  };
}

export default class Earnings extends React.Component<IProps> {
  public renderMoney(amount: number) {
    const formattedString = currencyFormat(amount);
    const [dollarAmount, centAmount] = formattedString.split('.', 2);
    return (<React.Fragment>
      <span style={{color: 'inherit', fontWeight: 'inherit'}}>{dollarAmount}</span>
      <span style={{color: 'inherit', fontWeight: 'inherit'}} className="text-xl">.{centAmount}</span>
    </React.Fragment>);
  }

  public render() {
    const { statementId, netDistribution, fees, servicesIncome } = this.props.earningsInfo;

    return (
      <div className="carousel-item p-5 bg-white rounded-lg h-100 mr-5 flex-no-shrink">
        <div className="w-100 h-100 text-center">
          <dl className="text-center">
            <dt className="heading-2">{TRANSLATIONS.income}</dt>
            <dd className="font-light text-4xl text-navy mb-6">{this.renderMoney(servicesIncome)}</dd>
            <dt className="heading-2">{TRANSLATIONS.fees}</dt>
            <dd className="font-light text-4xl text-navy mb-6">{this.renderMoney(fees)}</dd>
            <dt className="heading-2 pt-8 border-grey-dark border-t">net distribution</dt>
            <dd className="font-semibold text-primary text-4xl mb-4">{this.renderMoney(netDistribution)}</dd>
          </dl>
          <Link to={'/earnings/statement/' + statementId} className="font-semibold text-xs text-navy">View Statement</Link>
        </div>
      </div>
    );
  }
}
