// consider using https://www.npmjs.com/package/axios-auth-refresh
import axios from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from './tokens';

let isRefreshingAccessToken = false; // current
const subscribers:any[] = [];

const client = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

const subscribeTokenRefresh = (cb:any) => {
  subscribers.push(cb);
};

const onAccessTokenFetched = (token:any) => {
  subscribers.forEach((cb) => cb(token));
};

client.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    if (token && !(config.url || '').endsWith('/login')) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.request.use(
  (config) => {
    if (!!config.url && config.url.indexOf('clinicId=') === -1) {
      config.params = { clinicId: localStorage.getItem('clinicId') };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function refreshAccessToken() {
  const refreshToken = getRefreshToken();

  return axios
    .post(`/api/auth/refreshToken`, {
      refreshToken,
    })
    .then((response) => {
      const { newAccessToken, newRefreshToken } = response.data;

      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      return newAccessToken;
    });
}

// Automatically refresh token when we receive a 401 (Unauthorized)
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;

    if (status === 401) {
      if (!isRefreshingAccessToken) {
        isRefreshingAccessToken = true;

        refreshAccessToken().then((newToken: any) => {
          isRefreshingAccessToken = false;
          onAccessTokenFetched(newToken);
        });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token:any) => {
          // replace the expired token and retry
          originalRequest.headers.Authorization = 'Bearer ' + token;
          resolve(axios(originalRequest));
        });
      });

      return retryOriginalRequest;
    } else {
      return Promise.reject(error);
    }
  }
);

export default client;
