import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import withAppContext from '../hoc/withAppContext';
import { ISource } from '../source/ISource';
import StatementModal, { IStatement } from './StatementModal';

export interface IProps {
  source: ISource;
  contextValue: any;
}

export interface IState {
  statement: IStatement | null;
}

class StatementModalManager extends React.Component<
  IProps & RouteComponentProps<any>,
  IState
> {
  public state: IState = { statement: null };

  public onClose = () => {
    this.props.history.push('/earnings');
  };

  public componentDidMount() {
    const statementId = this.props.match.params.id;
    const clinicId = this.props.contextValue.appState.currentClinicId;
    this.props.source.statements
      .fetch({ statementId, clinicId })
      .then((data) => {
        this.setState({ statement: data });
      });
  }

  public componentWillUnmount() {
    this.props.source.statements.cancel();
  }

  public render() {
    const currentClinicId = this.props.contextValue.appState.currentClinicId;
    const clinics: { id: string, name: string, hubId: string }[] = this.props.contextValue.appState.userData.clinics;
    var currentClinic = clinics.find(clinic => {
      return clinic.id === currentClinicId;
    });
    var currentClinicHubId = currentClinic ? currentClinic.hubId : '';
    if (this.state.statement) {
      return (
        <StatementModal
          onClose={this.onClose}
          statement={this.state.statement}
          clinicId={currentClinicHubId}
        />
      );
    }
    return null;
  }
}

export default withRouter(withAppContext(StatementModalManager));
