import * as Sentry from '@sentry/browser';
import { createBrowserHistory } from 'history';
import React, { FunctionComponent } from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import AccountManager from './Account/AccountManager';
import AnalyticsPage from './Analytics/AnalyticsPage';
import App from './App';
import EarningsManager from './Earnings/EarningsManager';
import { isLoggedIn } from './lib/auth';
import Whitelabelling from './lib/Whitelabelling';
import Login from './Login';
import * as serviceWorker from './serviceWorker';

import DoctorHubSource from './source/DoctorHubSource';
import FakeDoctorHubSource from './source/FakeDoctorHubSource';

import '../styles.css';

Whitelabelling.loadCss();

const doctorHubSource = new DoctorHubSource();
const fakeDoctorHubSource = new FakeDoctorHubSource();

declare const ENV: string;
declare const GA_CODE: string;
declare const ENVIRONMENT: string; // e.g staging, uat, live

const history = createBrowserHistory();

const inProduction = ENV && ENV.toLowerCase() === 'production';
if (inProduction) {
  ReactGA.initialize(GA_CODE);

  history.listen((location: any) => {
    ReactGA.pageview(location.pathname);
  });
}

const trackUser = (userId: string) =>
  inProduction ? ReactGA.set({ userId }) : null;

const Root: FunctionComponent<{}> = () => {
  return (
    <Router history={history}>
      <div className="w-full h-full">
        <Switch>
          <Route
            exact={true}
            path="/login"
            render={() => {
              if (isLoggedIn()) {
                return <Redirect to="/" />;
              } else {
                return <Login trackUser={trackUser} source={doctorHubSource} />;
              }
            }}
          />
          <Route
            path="/"
            render={() => {
              if (isLoggedIn()) {
                return (
                  <App source={doctorHubSource}>
                    <Switch>
                      <Route
                        path="/earnings"
                        render={() => (
                          <EarningsManager source={doctorHubSource} />
                        )}
                      />
                      <Route
                        path="/intelligence"
                        render={() => (
                          <AnalyticsPage source={doctorHubSource} />
                        )}
                      />
                      <Route
                        exact={true}
                        path="/account"
                        render={() => (
                          <AccountManager source={doctorHubSource} />
                        )}
                      />
                      <Redirect from="/" exact={true} to="/earnings" />
                    </Switch>
                  </App>
                );
              } else {
                return <Redirect to="/login" />;
              }
            }}
          />
        </Switch>
      </div>
    </Router>
  );
};

if (inProduction) {
  Sentry.init({
    dsn: 'https://33543c21fa8c4f199e403205fefccc3b@sentry.io/1498962',
    environment: ENVIRONMENT,
  });
}

render(<Root />, document.getElementById('app'));

serviceWorker.register();
