import React from 'react';
import Dropdown from './Dropdown';

export interface IProps {
  clinics: Array<{
    id: number;
    name: string;
  }>;
  organisation: string;
  selectedClinicId: number | null;
  onChange: (value: string) => void;
}

const ClinicSelector = ({
  clinics,
  onChange,
  selectedClinicId,
  organisation,
}: IProps) => {
  const options = clinics.map((clinic) => ({
    text: clinic.name,
    value: `${clinic.id}`,
  }));

  return (
    <div className="flex justify-center py-4">
      <div className="text-2xs pr-2" style={{ color: '#B1B1BA' }}>
        {organisation}
      </div>
      <Dropdown
        options={options}
        value={selectedClinicId != null ? `${selectedClinicId}` : null}
        onChange={onChange}
      />
    </div>
  );
};

export default ClinicSelector;
