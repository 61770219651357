import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface IScreen {
  icon: string;
  title: string;
  route: string;
}

export interface IMenuProps {
  screens: IScreen[];
}

export class Menu extends React.Component<IMenuProps, {}> {
  public render() {
    const { screens } = this.props;

    return (
      <div className="menu flex justify-between bg-white px-8 py-2">
        {screens.map((screen) => {
          const SVG = require(`../../../public/svg/${screen.icon}`).default;
          return (
            <NavLink
              key={screen.route}
              to={screen.route}
              className="menu-item flex flex-col text-sm items-center no-underline opacity-50"
              activeClassName="opacity-100"
            >
              <SVG className="mb-2 text-navy" height="1.5em" />
              <div className="font-semibold text-navy text-2xs">{screen.title}</div>
            </NavLink>
          );
        })}
      </div>
    );
  }
}

export default Menu;
