import moment from 'moment';
import * as React from 'react';

// import client from '../lib/api/client';
import AppState from '../App';
import AppHeader from '../components/AppHeader';
import Help from '../components/Help';
import Select, { ISelectItem } from '../components/Select';
import { clinicHasChanged } from '../helpers';
import withAppContext from '../hoc/withAppContext';
import { IBillingsApiData, IBillingsDataItem, ISource } from '../source/ISource';
import BillingsPage from './Billings';
import ItemCodesPage from './ItemCodes';

import {
  NavLink,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';


interface ITimePeriod {
  quantity: number,
  unit: moment.unitOfTime.DurationConstructor
}

interface IState {
  itemCodesData: Array<{
    code: string;
    value: number;
  }>;
  billingsGraphData: IBillingsDataItem[];
  isFetchingItemCodesData: boolean;
  isFetchingBillingsData: boolean;
  fromDate: moment.Moment | null;
  toDate: moment.Moment;
}

interface IProps {
  source: ISource;
  contextValue: any;
}

const getEarliestDateFromBillingsData = (billingsData: IBillingsApiData) => {
  const dates = Object.keys(billingsData).sort();

  if (dates.length) {
    return moment(dates[0], 'YYYY-MM-DD');
  }

  return null;
};

const billingsDataWithEmptyDaysFilledIn = (
  fromDate: moment.Moment,
  toDate: moment.Moment,
  billingsData: IBillingsApiData
): IBillingsDataItem[] => {
  const graphData: IBillingsDataItem[] = [];

  for (
    let date = fromDate.clone();
    date.isSameOrBefore(toDate, 'day');
    date = date.clone().add(1, 'day')
  ) {
    const dateAsString = date.format('YYYY-MM-DD');

    const billingsDataForDate = billingsData[dateAsString];

    const graphDataForDate = {
      date,
      myBillings: 0,
      othersBillings: 0,
    };

    if (billingsDataForDate) {
      graphDataForDate.myBillings = billingsDataForDate.myBillings;
      graphDataForDate.othersBillings = billingsDataForDate.othersBillings;
    }

    graphData.push(graphDataForDate);
  }
  return graphData;
}

const averageBillingsDataBy = (graphData: IBillingsDataItem[], chunkPeriod: moment.unitOfTime.DurationConstructor): IBillingsDataItem[] => {
  const chunks: IBillingsDataItem[][] = [];
  let chunk: IBillingsDataItem[] = [];

  let firstDayOfChunk = graphData[0].date;
  for(const item of graphData) {
    if (item.date.isSame(firstDayOfChunk, chunkPeriod)) {
      chunk.push(item);
    } else {
      chunks.push(chunk);
      chunk = [item];
      firstDayOfChunk = item.date;
    }
  }
  chunks.push(chunk);

  return chunks.map((itemChunk: IBillingsDataItem[]): IBillingsDataItem => {
    const summedChunk: IBillingsDataItem = {
      date: itemChunk[0].date,
      myBillings: 0,
      othersBillings: 0,
    }

    const totalsAsArrays = itemChunk.reduce((acc, item) => {
      if (item.myBillings !== 0) {
        acc.myBillings.push(item.myBillings);
      }
      if (item.othersBillings !== 0) {
        acc.othersBillings.push(item.othersBillings);
      }
      return acc;
    }, {
      myBillings: [] as number[],
      othersBillings: [] as number[],
    });

    const averageArray = (acc: number, num: number, index: number, array: number[]) => {
      acc += num;
      if (index === array.length - 1) {
        return num / array.length;
      }
      return acc;
    };
    summedChunk.myBillings = totalsAsArrays.myBillings.reduce(averageArray, 0)
    summedChunk.othersBillings = totalsAsArrays.othersBillings.reduce(averageArray, 0)

    return summedChunk;
  })
}

const getGraphData = (
  fromDate: moment.Moment | null,
  toDate: moment.Moment,
  billingsData: IBillingsApiData
): IBillingsDataItem[] => {

  fromDate = fromDate ? fromDate
    : getEarliestDateFromBillingsData(billingsData) ||
      toDate.clone().subtract(14, 'days');

  const graphData = billingsDataWithEmptyDaysFilledIn(fromDate, toDate, billingsData);
  graphData.sort((a, b) => {
    if (a.date.isSame(b.date, 'day')) {
      return 0
    } else if (a.date.isBefore(b.date, 'day')) {
      return -1;
    }
    return 1;
  });

  if (toDate.diff(fromDate, 'months') > 8) {
    return averageBillingsDataBy(graphData, 'month');
  } else if (toDate.diff(fromDate, 'months') > 2) {
    return averageBillingsDataBy(graphData, 'week');
  } else {
    return graphData;
  }
};

const selectItems:Array<ISelectItem & ({period: ITimePeriod | null})> = [
  {
    label: 'Last 14 days',
    period: { quantity: 14, unit: 'days' },
  },
  {
    label: 'Last 3 months',
    period: { quantity: 3, unit: 'months' },
  },
  { label: 'Last Year', period: { quantity: 1, unit: 'years' } },
  { label: 'All', period: null },
];

class AnalyticsPage extends React.Component<
  IProps & RouteComponentProps<any>,
  IState
> {
  constructor(props: IProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      itemCodesData: [],
      billingsGraphData: [],
      fromDate: null,
      toDate: moment(moment().format('YYYY-MM-DD')),
      isFetchingItemCodesData: false,
      isFetchingBillingsData: false,
    };
  }

  public componentDidUpdate(prevProps: any) {
    const oldAppState = prevProps.contextValue.appState;
    const newAppState = this.props.contextValue.appState;

    if (clinicHasChanged(oldAppState, newAppState)) {
      this.loadData(this.state.fromDate, this.state.toDate);
    }
  }

  public componentWillUnmount() {
    this.props.source.billings.cancel();
    this.props.source.itemCodes.cancel();
  }

  public handleDateChange = (index: number) => {
    const period = selectItems[index].period;
    let fromDate = null;
    const toDate = moment(moment().format('YYYY-MM-DD'));

    if (period !== null) {
      fromDate = toDate.clone().subtract(period.quantity, period.unit);
    }

    this.setState({
      fromDate,
      toDate
    });

    this.loadData(fromDate, toDate);
  };

  public loadData = async (
    fromDate: moment.Moment | null,
    toDate: moment.Moment
  ) => {
    this.setState({
      isFetchingBillingsData: true,
      isFetchingItemCodesData: true,
    });

    const fromDateString = fromDate && fromDate.format('YYYY-MM-DD');
    const toDateString = toDate.format('YYYY-MM-DD');

    try {
      const billingsData = await this.props.source.billings.fetch({
        fromDate: fromDateString,
        toDate: toDateString,
      });
      const billingsGraphData = getGraphData(fromDate, toDate, billingsData);

      this.setState({
        billingsGraphData,
      });
    } catch(err) {
      console.error('Failed to get Billings Graph Data', err);
    } finally {
      this.setState({
        isFetchingBillingsData: false,
      });
    };

    try {
      const itemCodesData = await this.props.source.itemCodes.fetch({
        fromDate: fromDateString,
        toDate: toDateString
      })
      this.setState({
        itemCodesData: itemCodesData.items,
      });
    } catch (err) {
      console.error('Failed to get Item Codes Data', err);
    } finally {
      this.setState({
        isFetchingItemCodesData: false,
      });
    }
  };

  public getDateTitle = (): string => {
    const { fromDate, toDate } = this.state;

    if (fromDate && toDate) {
      const dateFormat: string = fromDate.isSame(moment(), 'year')
        ? 'ddd DD MMM'
        : 'ddd DD MMM YYYY';
      return `${fromDate.format(dateFormat)} - ${toDate.format(dateFormat)}`;
    }
    return 'Everything';
  };

  public render() {
    const showBillings =
      this.props.location.pathname.indexOf('/billings') !== -1;
    const showItemCodes =
      this.props.location.pathname.indexOf('/itemcodes') !== -1;

    let subpage;
    let helpText;
    let titleText;

    if (showBillings) {
      if (!this.state.billingsGraphData) {
        return null;
      }

      subpage = <BillingsPage data={this.state.billingsGraphData} />;
      helpText =
        'Your daily average billings per patient compared to the daily average billings per patient of other doctors';
      titleText = 'Billings per patient';
    } else if (showItemCodes) {
      if (!this.state.itemCodesData) {
        return null;
      }
      subpage = <ItemCodesPage itemCodesInfo={this.state.itemCodesData} />;
      helpText = 'Your highest value item codes for the period';
      titleText = 'Top item codes';
    } else {
      return <Redirect to="/intelligence/billings" />;
    }

    return (
      <React.Fragment>
        <div className="bg-grey-light">
          <AppHeader title={'Analytics'} />
          <div className="bg-grey-light flex text-center">
            <NavLink
              to="/intelligence/billings"
              className={`flex-1 p-6 text-2xs no-underline font-bold uppercase ${
                showBillings
                  ? 'border-b border-navy text-navy'
                  : 'text-grey-dark'
              }`}
            >
              billings
            </NavLink>
            <NavLink
              to="/intelligence/itemcodes"
              className={`flex-1 p-6 text-2xs no-underline font-bold uppercase ${
                showItemCodes
                  ? 'border-b border-navy text-navy'
                  : 'text-grey-dark'
              }`}
            >
              item codes
            </NavLink>
          </div>
        </div>
        <div className="gradient flex-grow flex flex-col overflow-y-scroll">
          <div className="text-center select-none shadow-inner pt-6">
            <div>
              <Select
                handleChange={this.handleDateChange}
                items={selectItems}
              />
            </div>
            <div className="font-bold pt-6 pb-3 pr-2 inline-block">
              {titleText}
            </div>
            <Help message={helpText} />
            <div className="text-2xs">{this.getDateTitle()}</div>
          </div>
          <div className="flex-grow">{subpage}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(AnalyticsPage));
