import React, { useState } from 'react';
import DropUpArrow from '../../../public/svg/arrow-drop-up.svg'
import DropDownArrow from '../../../public/svg/arrow-drop-down.svg'
import Download from '../../../public/svg/download.svg'
import { CSSTransition } from 'react-transition-group';
import { AvailableDocumentGroup as IAvailableDocumentGroup } from '../types/AvailableDocumentGroup';

interface IProps {
  link: string;
  availableDocumentGroups: IAvailableDocumentGroup[];
  downloadStatement: (link: string) => Promise<void>;
}

const DownloadDropdown = ({link, availableDocumentGroups, downloadStatement}: IProps) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const createDocumentLabel = (document : string, groupName: string) => {
    if (document === "disbursementStatement") {
      return "Disbursement Statement"
    } else if (document === "invoice") {
      return "Tax Invoice"
    } else if (document === "rcti") {
      return "RCTI"
    } else {
      return groupName;
    }
  }

  const createDownloadUrl = (link: string, document: string, group: IAvailableDocumentGroup) => {
    if (document === "simpleStatement") {
      const locationGroupQuery = group._id ? `?locationGroup=${group._id}` : "";
      return `${link}${locationGroupQuery}`
    }
    const locationGroupQuery = group._id ? `&locationGroup=${group._id}` : "";
    const documentQuery = document.toUpperCase();

    return `${link}?document=${documentQuery}${locationGroupQuery}`
  }
  
  const getDocumentsAvailableForGroup = (group: IAvailableDocumentGroup) => {
    const documentsAvailable = Object.keys(group).filter(groupKey  => {
      return group[groupKey]
    }).filter(key => !['_id', 'name', 'locations'].includes(key));
  
    return documentsAvailable;
  }

  const makeOptionsFromDocumentsAvailable = (group : IAvailableDocumentGroup) => {
    const documentsAvailable = getDocumentsAvailableForGroup(group);
    return documentsAvailable.map((document: string) => {
      return {
        url: createDownloadUrl(link, document, group),
        label: createDocumentLabel(document, group.name)
      }
    })
  }

  const shouldShowGroupHeader = (group: IAvailableDocumentGroup, availableDocumentGroups: IAvailableDocumentGroup[]) => {
    const isSimpleStatement = group.simpleStatement;
    const onlyHasDefaultGroup = availableDocumentGroups.length === 1 && !group._id;

    return !isSimpleStatement || onlyHasDefaultGroup;
  }

  return (
    <>
      <button
        onClick={() => {
          setDropdownIsOpen(!dropdownIsOpen)
        }}
        className="bg-primary w-full rounded-lg font-semibold py-3 text-white flex flex-row justify-center no-underline items-center"
      >
        Download
        <span className="ml-2 flex">
          {dropdownIsOpen ? <DropUpArrow width="1.5rem" height="1.5rem"/> : <DropDownArrow width="1.5rem" height="1.5rem"/>}
        </span>
      </button>
        {availableDocumentGroups.map(group => {
          const options = makeOptionsFromDocumentsAvailable(group)
          const isDetailedStatementOrHasLocationGroup = shouldShowGroupHeader(group, availableDocumentGroups);
          return (
          <CSSTransition in={dropdownIsOpen} timeout={200} classNames="fade" unmountOnExit key={group.name}>
            <div className="p-4 mt-2 bg-grey-light rounded-lg">
              {isDetailedStatementOrHasLocationGroup && <h4 className="mb-4 font-semibold">{group.name}</h4>}
              <ul className="list-reset">
              {options.map(option => {
                return (
                  <li className="pb-2 flex items-center pr-2" key={option.label}>
                    <Download width="1.5rem"/>
                    <a href="#" className="ml-1" onClick={() => downloadStatement(option.url)}>
                      {option.label}
                    </a>
                  </li>
                )
              })}
              </ul>
            </div>
          </CSSTransition>
          )
        })}
    </>
  )
};

export default DownloadDropdown;