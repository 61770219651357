import apiClient from './api/client';

function addStyleString(str: string) {
  const node = document.createElement('style');
  node.id = 'whitelabelled-style';
  node.innerHTML = str;
  document.body.appendChild(node);
}

class Whitelabelling {
  public loadCss(): Promise<void> {
    return apiClient.get('/api/whitelabelling.css').then((response) => {
      addStyleString(response.data);
    });
  }

  public loadDummyCss(): void {
    addStyleString(`
      .bg-primary { background-color: blue; }
      .bg-primary-10 { background-color: lightblue; }
      .text-primary { color: blue; }
      .bg-secondary { background-color: yellow; }
      .text-secondary { color: yellow; }
      .bg-tertiary { background-color: green; }
      .text-tertiary { color: green; }
      .bg-item-1 { background-color: rebeccapurple }
      .text-item-1 { color: rebeccapurple }
      .bg-item-2 { background-color: red }
      .text-item-2 { color: red }
      .bg-item-3 { background-color: green }
      .text-item-3 { color: green }
      .bg-item-4 { background-color: lime }
      .text-item-4 { color: lime }
      .bg-item-5 { background-color: pink }
      .text-item-5 { color: pink }
      .bg-item-6 { background-color: aquamarine }
      .text-item-6 { color: aquamarine }
      .bg-item-7 { background-color: azure }
      .text-item-7 { color: azure }
      .bg-item-8 { background-color: beige }
      .text-item-8 { color: magenta }
      .bg-item-9 { background-color: darkcyan }
      .text-item-9 { color: darkcyan }
      .bg-item-10 { background-color: magenta }
      .text-item-10 { color: magenta }
    `);
  }

  public unloadCss(): void {
    // Unload whitelabelled styles
    const style = document.getElementById('whitelabelled-style');
    if (style) {
      style.outerHTML = '';
    }
  }
}

export default new Whitelabelling();
