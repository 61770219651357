import React from 'react';
import AppContext from '../AppContext';

const withAppContext = (Wrapped: React.ComponentType<any>) => (props: any) => (
  <AppContext.Consumer>
    {(value) => <Wrapped {...props} contextValue={value} />}
  </AppContext.Consumer>
);

export default withAppContext;
