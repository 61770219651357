import * as React from 'react';
import ItemCode from './ItemCode';

export interface IProps {
  itemCodesInfo: Array<{ code: string; value: number }>;
}

export default class ItemCodesPage extends React.Component<IProps> {
  public render() {
    const total = this.props.itemCodesInfo.reduce(
      (acc, itemCodeInfo) => acc + itemCodeInfo.value,
      0
    );

    return (<>
      {this.props.itemCodesInfo
      .sort((a, b) => b.value - a.value)
      .map((x, i) => {
        const percentage = Math.round((x.value / total) * 100);

        return (
          <ItemCode
            key={x.code}
            name={x.code}
            amount={x.value}
            color={i + 1}
            percentage={percentage}
          />
        );
      })}

      <div className="text-grey-dark text-sm text-center my-8">
        <a className="text-grey-dark no-underline" target="_blank" href="https://www.cubiko.com.au/">
          For deeper insights into your data billing data, <br />we recommend our partner
          <img src="https://www.cubiko.com.au/wp-content/uploads/2022/01/Cubiko-SVG-logo.svg" title="Cubiko SVG logo" alt="Cubiko" style={{height: "20px", "verticalAlign": "middle"}} className="ml-1" />
        </a>
      </div>
    </>);
  }
}
