import moment from 'moment';
import * as React from 'react';
import client from '../lib/api/client';
import { currencyFormat } from '../lib/utilities';

import Close from '../../../public/svg/close.svg';
import { ITranslations } from '../types/ITranslations';
import { AvailableDocumentGroup as IAvailableDocumentGroup } from '../types/AvailableDocumentGroup';
import DownloadStatement from '../components/DownloadStatement'

declare const TRANSLATIONS: ITranslations;
declare const HUB_SITES_WITHOUT_STATEMENT_DOWNLOAD: string[];

interface IStatementLine {
  billings: number;
  earnings: number;
  receipts?: number;
}

interface IDatedStatementLine extends IStatementLine {
  date: string;
}

export interface IStatement {
  name: string;
  toDate: string;
  fromDate: string;
  link: string | null;
  lines: IDatedStatementLine[];
  late: IStatementLine | null;
  availableDocumentGroups: IAvailableDocumentGroup[];
  netBillingsAdjustments?: number;
  netEarningsAdjustments?: number;
}

export interface IProps {
  onClose: () => any;
  statement: IStatement;
  clinicId: string;
}

function getRowClass(columns: number) {
  const columnsClass = columns === 3 ? 'w-1/3' : 'w-1/4'

  return `inline-block ${columnsClass} text-sm`;
}

function getRowClassRight(columns: number) {
  return `${getRowClass(columns)} text-right`;
}

function lineHasReceipts(line: IStatementLine | null) {
  return line && line.receipts !== undefined;
}

function shouldShowStatementDownloadButton(clinicId: string): boolean {
  const idsThatShouldNotShowButton = HUB_SITES_WITHOUT_STATEMENT_DOWNLOAD || [];
  return !idsThatShouldNotShowButton.find(id => id === clinicId);
}

const SubTotalRow = ({
  totalBillings,
  totalReceipts,
  totalEarnings,
}: {
  totalBillings: number;
  totalReceipts: number | null;
  totalEarnings: number;
}) => {
  const columns = totalReceipts ? 4 : 3;

  return (
    <div className="px-8 py-4 bg-grey-light">
      <div className={getRowClass(columns)}>Subtotal</div>
      <div className={`${getRowClassRight(columns)} font-semibold`}>
        {currencyFormat(totalBillings)}
      </div>
      {totalReceipts && (
        <div className={`${getRowClassRight(columns)} font-semibold`}>
          {currencyFormat(totalReceipts)}
        </div>
      )}
      <div className={`${getRowClassRight(columns)} font-semibold`}>
        {currencyFormat(totalEarnings)}
      </div>
    </div>
  );
};

const AdjustmentsRow = ({
  netBillingsAdjustments,
  netEarningsAdjustments,
  hasReceipts = false,
}: {
  netBillingsAdjustments: number;
  netEarningsAdjustments: number;
  hasReceipts?: boolean;
}) => {
  const columns = hasReceipts ? 4 : 3;

  return (
    <div className="px-8 py-4 bg-grey-light">
      <div className={getRowClass(columns)}>Net Adjustments</div>
      <div className={`${getRowClassRight(columns)} font-semibold`}>
        {currencyFormat(netBillingsAdjustments)}
      </div>
      {hasReceipts && (
        <div className={`${getRowClassRight(columns)} font-semibold`} />
      )}
      <div className={`${getRowClassRight(columns)} font-semibold`}>
        {currencyFormat(netEarningsAdjustments)}
      </div>
    </div>
  );
};

class StatementModal extends React.Component<IProps, {}> {
  public formatDate(date: string) {
    return moment(date, 'YYYY-MM-DD').format('ddd D MMM');
  }

  /**
   * Get the late row for the statement table.
   * @param line The line representing the late figures.
   * @param topMargin Whether the line should have a top margin (looks ugly against a row with a background).
   */
  public lateRow(line: IStatementLine | null, topMargin: boolean) {
    const renderReceipts = lineHasReceipts(line);
    const columns = renderReceipts ? 4 : 3;

    if (
      line === null ||
      (line.billings === 0 && line.receipts === 0 && line.earnings === 0)
    ) {
      return null;
    }

    const wrapperClass = `bg-primary-10 leading-normal px-3 pt-1 pb-2 rounded ${
      topMargin ? 'mt-2' : ''
    }`;

    const figureClass = `${getRowClassRight(columns)} text-primary`;
    return (
      <li key="late" className={wrapperClass} role="row">
        <div className={getRowClass(columns)}>
          <span className="bg-secondary text-white text-2xs uppercase py-1 px-2 rounded font-semibold">
            Late
          </span>
        </div>
        <div className={figureClass}>{currencyFormat(line.billings)}</div>
        {renderReceipts && typeof line.receipts === 'number' && (
          <div className={figureClass}>{currencyFormat(line.receipts)}</div>
        )}
        <div className={figureClass}>{currencyFormat(line.earnings)}</div>
      </li>
    );
  }

  /**
   * Get a row for the statement table.
   * @param line Line representing a day of figures in the statement.
   * @param index The index of the line in the array.
   */
  public statementRow(line: IDatedStatementLine, index: number) {
    const renderReceipts = lineHasReceipts(line);
    const columns = renderReceipts ? 4 : 3;

    return (
      <li
        key={line.date}
        className={`${
          index % 2 === 0 ? 'bg-grey' : ''
        } leading-normal px-3 pt-1 pb-2 rounded`}
        role="row"
      >
        <div className={`${getRowClass(columns)} font-semibold`}>
          {moment(line.date).format('D  MMM')}
        </div>
        <div className={getRowClassRight(columns)}>
          {currencyFormat(line.billings)}
        </div>
        {renderReceipts && typeof line.receipts === 'number' && (
          <div className={getRowClassRight(columns)}>
            {currencyFormat(line.receipts)}
          </div>
        )}
        <div className={getRowClassRight(columns)}>
          {currencyFormat(line.earnings)}
        </div>
      </li>
    );
  }

  /**
   * Get the details for the statment - might be a table or "empty".
   * @param statement The statement to generate the details for.
   */
  public statementDetails(statement: IStatement) {
    const renderReceipts =
      statement.lines.length !== 0 && statement.lines[0].receipts !== undefined;
    const columns = renderReceipts ? 4 : 3;
    const hasAdjustments =
      !!statement.netBillingsAdjustments || !!statement.netEarningsAdjustments;
    const netBillingsAdjustments = statement.netBillingsAdjustments || 0;
    const netEarningsAdjustments = statement.netEarningsAdjustments || 0;

    if (!statement.lines.length) {
      return (
        <div className="flex flex-col justify-center flex-grow text-center text-grey-darker leading-normal">
          This billing period hasn't
          <br />
          received any fees yet
        </div>
      );
    }

    return (
      <React.Fragment>
        {/* Header row */}
        <div className="mt-4 mb-2 px-8">
          <div className={`${getRowClass(columns)} text-secondary`}>Date</div>
          <div className={`${getRowClassRight(columns)} text-secondary`}>
            Billings
          </div>
          {renderReceipts && (
            <div className={`${getRowClassRight(columns)} text-secondary`}>
              Receipts
            </div>
          )}
          <div className={`${getRowClassRight(columns)} text-secondary`}>
            {TRANSLATIONS.earnings}
          </div>
        </div>

        {/* Info table rows (scrollable) */}
        <ul className="px-6 list-reset flex-grow overflow-y-scroll pb-4">
          {this.statementLinesSortedByDate(statement.lines).map(
            this.statementRow
          )}
          {this.lateRow(statement.late, !!(statement.lines.length % 2))}
        </ul>

        {hasAdjustments && (
          <React.Fragment>
            <SubTotalRow
              totalBillings={this.tableSum('billings')}
              totalReceipts={renderReceipts ? this.tableSum('receipts') : null}
              totalEarnings={this.tableSum('earnings')}
            />
            <AdjustmentsRow
              netBillingsAdjustments={netBillingsAdjustments}
              netEarningsAdjustments={netEarningsAdjustments}
              hasReceipts={renderReceipts}
            />
          </React.Fragment>
        )}
        <div className="px-8 py-4 bg-grey-light">
          <div className={getRowClass(columns)}>Totals</div>
          <div className={`${getRowClassRight(columns)} font-semibold`}>
            {currencyFormat(this.tableSum('billings') + netBillingsAdjustments)}
          </div>
          {renderReceipts && (
            <div className={`${getRowClassRight(columns)} font-semibold`}>
              {currencyFormat(this.tableSum('receipts'))}
            </div>
          )}
          <div className={`${getRowClassRight(columns)} font-semibold`}>
            {currencyFormat(this.tableSum('earnings') + netEarningsAdjustments)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  public tableSum(key: keyof IStatementLine) {
    return this.props.statement.lines.reduce(
      (sum, line) => sum + (line[key] || 0),
      0
    );
  }

  public async downloadStatement(link: string) {
    const response = await client.get(link, {
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'statement.pdf';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent) => {
      var file = new Blob([response.data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      if (reader.result !== null) {
        const anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = fileName;
        anchor.target = '_self';
        anchor.click();
      }
    };

    reader.readAsDataURL(response.data);
  }

  public render() {
    const showStatementDownloadButton = shouldShowStatementDownloadButton(this.props.clinicId);
    return (
      <div
        className="z-10 absolute pin-x pin-y pt-6"
        style={{ animation: 'slideFromBottom 0.5s' }}
      >
        <div className="h-full flex flex-col bg-white rounded-lg">
          {/* Heading */}
          <div className="flex flex-row flex-no-shrink justify-between items-start mt-8 px-8">
            <div>
              <div>
                <h1 className="inline text-2xl font-semibold">Statement</h1>{' '}
                <span>{this.props.statement.name}</span>
              </div>
              <div className="text-grey-darker">
                {this.formatDate(this.props.statement.fromDate)} -{' '}
                {this.formatDate(this.props.statement.toDate)}
              </div>
            </div>
            <button className="mt-1" onClick={this.props.onClose}>
              <Close width="1rem" />
            </button>
          </div>

          {/* Download button */}
          <div className="mt-4 px-8 flex-no-shink">
          {showStatementDownloadButton && <DownloadStatement link={this.props.statement.link} 
              availableDocumentGroups={this.props.statement.availableDocumentGroups}
              downloadStatement={this.downloadStatement.bind(this)}/>} 
          </div>

          {this.statementDetails(this.props.statement)}
        </div>
      </div>
    );
  }
  private statementLinesSortedByDate(lines: IDatedStatementLine[]) {
    return lines.sort((a, b) => {
      if (a.date === b.date) {
        return 0;
      }
      return a.date < b.date ? -1 : 1;
    });
  }
}

export default StatementModal;
