import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Menu, { IScreen } from './components/Menu';
import { ISource, IUser } from './source/ISource';

import AppContext from './AppContext';
import ClinicSelector from './components/ClinicSelector';
import client from './lib/api/client';

export interface IProps {
  loggedIn?: boolean;
  history: any;

  source: ISource;
}

interface IState {
  userData: IUser | null;
  currentClinicId: number | null;
}

const screens: IScreen[] = [
  {
    icon: 'earnings.svg',
    title: 'Earnings',
    route: '/earnings',
  },
  {
    icon: 'analytics.svg',
    title: 'Analytics',
    route: '/intelligence',
  },
  {
    icon: 'account.svg',
    title: 'Account',
    route: '/account',
  },
];

export interface AppState {
  userData: IUser | null;
  currentClinicId: number | null;
}

class App extends React.Component<IProps & RouteComponentProps<any>, IState> {
  constructor(props: any) {
    super(props);

    this.onClinicChanged = this.onClinicChanged.bind(this);

    this.state = {
      userData: null,
      currentClinicId: null,
    };
  }

  public getClinicId() {
    return parseInt(localStorage.getItem('clinicId') || '0', 10);
  }

  public setClinicId(id: number) {
    localStorage.setItem('clinicId', `${id}`);
  }

  public async componentDidMount() {
    this.props.source.account.fetch().then((user) => {
      let clinicId: number = user.clinics[0].id;

      const storedClinicId = this.getClinicId();

      if (storedClinicId) {
        clinicId = storedClinicId;
      } else {
        this.setClinicId(clinicId);
      }

      this.setState({
        userData: user,
        currentClinicId: clinicId,
      });
    });
  }

  public componentWillUnmount() {
    this.props.source.account.cancel();
  }

  public onClinicChanged(clinicId: string) {
    this.setState(
      {
        currentClinicId: parseInt(clinicId),
      },
      () => {
        localStorage.setItem('clinicId', `${clinicId}`);
      }
    );
  }

  public render() {
    const appState = {
      currentClinicId: this.state.currentClinicId,
      userData: this.state.userData,
    };

    return (
      <div className="app flex flex-col w-full h-full">
        {this.state.userData && (
          <AppContext.Provider value={{ appState }}>
            <div className="flex flex-col flex-grow overflow-y-scroll bg-grey-light">
              <ClinicSelector
                organisation={this.state.userData.organisation}
                clinics={this.state.userData.clinics}
                selectedClinicId={this.state.currentClinicId}
                onChange={this.onClinicChanged}
              />
              {this.props.children}
            </div>
            <div>
              <Menu screens={screens} />
            </div>
          </AppContext.Provider>
        )}
      </div>
    );
  }
}

export default withRouter(App);
