import * as React from 'react';
import { currencyFormat } from '../lib/utilities';

interface IProps {
 name: string,
 amount: number,
 percentage: number,
 color: number,
}

const ItemCode: React.FunctionComponent<IProps> = (props) => {

  const percentageBarWidth = {
    width: props.percentage + '%'
  };

  return (
    <div className="flex items-center m-2">
      <div className="itemCodeAndAmount">
        <div className="text-right p-1 font-extrabold text-2xs w-24 truncate">{props.name}</div>
        <div className="text-right p-1 font-semibold text-3xs">{currencyFormat(props.amount)}</div>
      </div>
      <div  className="flex-1 h-10 rounded overflow-hidden bg-grey-light">
        <div style={percentageBarWidth} className={`h-full bg-item-${props.color}`} />
      </div>
      <div className={`w-10 justify-center font-extrabold text-2xs text-right text-item-${props.color}`}>{props.percentage}%</div>
    </div>
  );
}

export default ItemCode;
