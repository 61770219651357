import axios from 'axios';
import qs from 'qs';
import { IBillingsApiData, ILoader } from '../ISource';

import client from '../../lib/api/client';

const CancelToken = axios.CancelToken;

interface IFetchParams {
  fromDate: string | null;
  toDate: string | null;
  clinicId: string;
}

export default class BillingsLoader implements ILoader<IBillingsApiData> {
  private canceller: any;

  public cancel() {
    if (this.canceller) {
      this.canceller.cancel();
    }
  }

  public fetch({
    fromDate,
    toDate,
    clinicId,
  }: IFetchParams): Promise<IBillingsApiData> {
    this.canceller = CancelToken.source();

    const queryObject = {
      fromDate,
      toDate,
      clinicId,
    };

    const url = `/api/intelligence/billings?${qs.stringify(queryObject)}`;

    return client
      .get(url, { cancelToken: this.canceller.cancelToken })
      .then((res) => {
        const billingsData = res.data.data;
        return billingsData;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request cancelled', thrown.message);
        } else {
          console.error('ERROR:', thrown.message);
        }

        return null;
      });
  }
}
