import qs from 'qs';
import client from '../lib/api/client';
import { ISource } from './ISource';

import AccountLoader from './loaders/AccountLoader';
import BillingsLoader from './loaders/BillingsLoader';
import EarningsLoader from './loaders/EarningsLoader';
import ItemCodesLoader from './loaders/ItemCodesLoader';
import StatementLoader from './loaders/StatementLoader';

export default class DoctorHubSource implements ISource {
  public billings = new BillingsLoader();
  public itemCodes = new ItemCodesLoader();
  public earnings = new EarningsLoader();
  public account = new AccountLoader();
  public statements = new StatementLoader();
}
