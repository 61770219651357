import * as React from 'react';

import moment from 'moment';
import { clinicHasChanged } from '../helpers';
import withAppContext from '../hoc/withAppContext';
import { IEarningsApiData, ISource } from '../source/ISource';

import EarningsPage from './EarningsPage';

interface IEarnings {
  fromDate: string;
  toDate: string;
  statementId: string;
  netDistribution: number;
  fees: number;
  servicesIncome: number,
}

interface IState {
  earnings: IEarnings[];
  loading: boolean;
  periodEndDate: string | null;
}

export interface IProps {
  source: ISource;
  contextValue: any;
}

const statementApiDataToEarningsObject = (statement: IEarningsApiData["statements"][0]): IEarnings => ({
  fromDate: statement.fromDate,
  toDate: statement.toDate,
  statementId: statement.statementId,
  netDistribution: statement.earnings,
  fees: -1 * (statement.serviceFeeWithoutGST + statement.serviceFeeGST),
  servicesIncome: (statement.incomeWithoutGST || 0) + (statement.incomeGST || 0),
});

class EarningsManager extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.handleMoreData = this.handleMoreData.bind(this);
    this.state = { earnings: [], loading: false, periodEndDate: null };
  }

  public componentDidUpdate(prevProps: any) {
    const oldAppState = prevProps.contextValue.appState;
    const newAppState = this.props.contextValue.appState;

    if (clinicHasChanged(oldAppState, newAppState)) {
      this.loadData(this.state.periodEndDate, true);
    }
  }

  public loadData = (mostRecentPeriodEndDate: string | null, replace = false) => {
    this.setState({ loading: true });

    const numberOfStatementPeriods = 4;
    const clinicId = this.props.contextValue.appState.currentClinicId;

    return this.props.source.earnings
      .fetch({ mostRecentPeriodEndDate, numberOfStatementPeriods, clinicId })
      .then((data: IEarningsApiData) => {
        const newEarnings = data.statements.map(statementApiDataToEarningsObject);

        const allEarnings = replace ? newEarnings : this.state.earnings.concat(newEarnings);

        allEarnings.sort((a, b) => {
          if (a.toDate === b.toDate) {
            return 0;
          }
          return a.toDate > b.toDate ? -1 : 1;
        });
        
        this.setState(() => ({
          earnings: allEarnings,
          loading: false,
        }));
      });
  };

  public handleMoreData(earliestPeriodStartDateString: string) {
    const earliestPeriodStartDate = moment(earliestPeriodStartDateString);
    const periodEndDate = earliestPeriodStartDate
      .add(-1, 'days')
      .format('YYYY-MM-DD');

    this.setState({ periodEndDate });
    this.loadData(periodEndDate);
  }

  public componentWillUnmount() {
    this.props.source.earnings.cancel();
  }

  public componentDidMount() {
    this.loadData(null);
  }

  public render() {
    return (
      <React.Fragment>
        <EarningsPage
          getMoreStatements={this.handleMoreData}
          earningsInfo={this.state.earnings}
          source={this.props.source}
        />
        {this.state.loading ? <span>loading ...</span> : null}
      </React.Fragment>
    );
  }
}

export default withAppContext(EarningsManager);
