import axios from 'axios';
import qs from 'qs';
import { IEarningsApiData, ILoader } from '../ISource';

import client from '../../lib/api/client';

const CancelToken = axios.CancelToken;

interface IFetchParams {
  numberOfStatementPeriods: number;
  mostRecentPeriodEndDate: string | null;
  clinicId: string;
}

export default class EarningsLoader implements ILoader<IEarningsApiData> {
  private canceller: any;

  public cancel() {
    if (this.canceller) {
      this.canceller.cancel();
    }
  }

  public fetch({
    numberOfStatementPeriods,
    mostRecentPeriodEndDate,
    clinicId,
  }: IFetchParams): Promise<IEarningsApiData> {
    this.canceller = CancelToken.source();

    const queryObject = {
      number: numberOfStatementPeriods,
      toDate: mostRecentPeriodEndDate || undefined,
      clinicId,
    };

    const url = `/api/earnings?${qs.stringify(queryObject)}`;

    return client
      .get(url, { cancelToken: this.canceller.cancelToken })
      .then((res) => {
        return res.data;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request cancelled', thrown.message);
        } else {
          console.error('ERROR:', thrown.message);
        }

        return null;
      });
  }
}
