import React from 'react';
import DownloadDropdown from './DownloadDropdown'
import DownloadArrow from '../../../public/svg/arrow-download.svg'
import { AvailableDocumentGroup as IAvailableDocumentGroup } from '../types/AvailableDocumentGroup';

interface IProps {
  link: string | null;
  availableDocumentGroups: IAvailableDocumentGroup[];
  downloadStatement: (link: string) => Promise<void>;
}

const getDocumentsAvailableForGroup = (group: IAvailableDocumentGroup) => {
  const documentsAvailable = Object.keys(group).filter(groupKey  => {
    return group[groupKey]
  }).filter(key => !['_id', 'name', 'locations'].includes(key));

  return documentsAvailable;
}

const Download = ({link, availableDocumentGroups, downloadStatement}: IProps) => {

  const showDropDown = availableDocumentGroups.length > 1 || getDocumentsAvailableForGroup(availableDocumentGroups[0]).length > 1;

  if (!link) {
    return (
      <div className="w-full rounded-lg bg-grey-light text-grey-darker text-sm text-center p-2">
        This statement will be available to download at the end of the billing
        period
      </div>
    );
  }

  return (
    <>
      {showDropDown ? (
        <DownloadDropdown link={link} availableDocumentGroups={availableDocumentGroups} downloadStatement={downloadStatement}/>
      ) : (
        <button
          onClick={() => downloadStatement(link)}
          className="bg-primary w-full rounded-lg font-semibold py-3 text-white flex flex-row justify-center no-underline"
        >
          <span className="mr-1 mt-0.15">
            <DownloadArrow width="1rem" height="1rem"/>
          </span>
          Download
        </button>
      )}
    </>
  );
}

export default Download;