import axios from 'axios';
import qs from 'qs';
import { IItemCodesApiData, ILoader } from '../ISource';

import client from '../../lib/api/client';

const CancelToken = axios.CancelToken;

interface IFetchParams {
  fromDate: string | null;
  toDate: string | null;
  clinicId: string;
}

export default class ItemCodesLoader implements ILoader<IItemCodesApiData> {
  private canceller: any;

  public cancel() {
    if (this.canceller) {
      this.canceller.cancel();
    }
  }

  public fetch({
    fromDate,
    toDate,
    clinicId,
  }: IFetchParams): Promise<IItemCodesApiData> {
    this.canceller = CancelToken.source();

    const queryObject = {
      fromDate,
      toDate,
      clinicId,
    };

    const url = `/api/intelligence/itemCodes?${qs.stringify(queryObject)}`;

    return client
      .get(url, { cancelToken: this.canceller.cancelToken })
      .then((res) => {
        return res.data;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request cancelled', thrown.message);
        } else {
          console.error('ERROR:', thrown.message);
        }

        return null;
      });
  }
}
