import * as React from 'react';
import withAppContext from '../hoc/withAppContext';
import { ISource } from '../source/ISource';
import AccountScreen from './AccountPage';

interface IState {
  name: string;
  email: string;
  organisation: string;
  clinic: string;
}

export interface IProps {
  source: ISource;
  contextValue: any;
}

class AccountManager extends React.Component<IProps, IState> {
  public render() {
    const appState = this.props.contextValue.appState;
    const { userData, currentClinicId } = appState;

    const currentClinic = userData.clinics.find(
      (c: any) => c.id === currentClinicId
    );

    return (
      <AccountScreen
        userInfo={{
          name: userData.name,
          email: userData.email,
          organisation: userData.organisation,
          clinic: currentClinic.name,
          accountingPackageConnected: !!userData.xero_account,
          accountingPackageEmail: userData.xero_account || '',
        }}
      />
    );
  }
}

export default withAppContext(AccountManager);
