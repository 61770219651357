import * as React from 'react';

export interface IProps {
  children: any;
};

function Header({ children }: IProps) {
  return (
  <div className="mb-2 mt-4">
    {children}
  </div>);
};

export default Header;
