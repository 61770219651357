import { History } from 'history';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import { disconnectFromXero, logout } from '../lib/auth';
import AccountingPackageConnection from './AccountingPackageConnection'
import { ITranslations } from '../types/ITranslations';

declare const TRANSLATIONS: ITranslations;
declare const CUSTOMISATIONS: string | null;

export interface IProps {
  history: History;
  userInfo: {
    name: string;
    email: string;
    organisation: string;
    clinic: string;
    accountingPackageConnected: boolean;
    accountingPackageEmail: string;
  };
}

export interface IState {
  accountingPackageConnected: boolean;
}

class AccountPage extends React.Component<
  IProps & RouteComponentProps<any>,
  IState
> {
  public state = {
    accountingPackageConnected: this.props.userInfo.accountingPackageConnected,
  };

  public handleDisconnect = () => {
    return disconnectFromXero(this.props.userInfo.clinic)
    .then(() => {
      this.setState((prevState) => ({
        accountingPackageConnected: !prevState.accountingPackageConnected,
      }));
    });
  };

  public handleLogout = () => {
    logout();
    this.props.history.push('/login');
  };

  public handleContact = () => {
    window.location.href = TRANSLATIONS.mailto;
  };

  public render() {
    return (
      <div className="flex flex-col flex-grow w-full h-full bg-grey-light">
        <AppHeader title="Account" />
        <div className="flex-grow pt-2 pb-3 p-6">
          <dl>
            <dt className="heading-2">name</dt>
            <dd className="description-2">{this.props.userInfo.name}</dd>
            <dt className="heading-2">email</dt>
            <dd className="description-2">{this.props.userInfo.email}</dd>
            <dt className="heading-2">organisation</dt>
            <dd className="description-2">{this.props.userInfo.organisation}</dd>
            <dt className="heading-2">clinic</dt>
            <dd className="description-2">{this.props.userInfo.clinic}</dd>
          </dl>
          {CUSTOMISATIONS === "ochre" ? (
            <button
            onClick={this.handleContact}
            className="bg-tertiary text-xs w-full text-white rounded-lg p-3 font-extrabold"
          >
            Contact Ochre Health
          </button>
          ) : (
            <AccountingPackageConnection
              accountingPackageConnected={this.state.accountingPackageConnected}
              accountingPackageEmail={this.props.userInfo.accountingPackageEmail}
              handleDisconnect={this.handleDisconnect}
              handleContact={this.handleContact}
            />
          )}

        </div>
        <div className="pt-4 pb-4 pl-6 pr-6 bg-white gradient">
          <button
            onClick={this.handleLogout}
            className="bg-primary text-xs w-full text-white rounded-lg p-3 font-extrabold"
          >
            Logout
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountPage);
