import * as React from 'react';

import Header from './Header';

export interface IProps {
  title: string;
}

function AppHeader({ title }:IProps) {
  return <Header>
    <h1 className="font-semibold text-center text-secondary text-2xl">{title}</h1>
  </Header>;
};

export default AppHeader;
