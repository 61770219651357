import * as React from 'react';
import { Line } from 'react-chartjs-2';
import tinycolor from 'tinycolor2';
import AppContext from '../AppContext';
import { IBillingsDataItem, IUser } from '../source/ISource';

export interface IProps {
  data: IBillingsDataItem[];
}

export default class BillingsPage extends React.Component<IProps> {
  public static contextType = AppContext;

  public prepBillingsDataFor = (owner: 'othersBillings' | 'myBillings'): number[] => {
    const data = this.props.data.map(item => item[owner] === 0 ? NaN : item[owner]);
    if (isNaN(data[0])) {
      data[0] = 0;
    }
    if (isNaN(data[data.length - 1])) {
      data[data.length - 1] = 0;
    }
    return data;
  };

  public render() {
    const { data } = this.props;
    const userData: any = this.context.appState.userData;

    const labels = data.map((item) => item.date);
    const othersBillingsData = this.prepBillingsDataFor('othersBillings');
    const myBillingsData = this.prepBillingsDataFor('myBillings');

    const myColor = userData.colors.primary;
    const otherColor = userData.colors.tertiary;

    return (
      <Line
        legend={{
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 10,
          },
        }}
        data={{
          datasets: [
            {
              data: myBillingsData,
              label: 'My Billings',
              borderColor: myColor,
              pointBackgroundColor: myColor,
              hitRadius: 5,
              borderWidth: 2,
              spanGaps: true,
              backgroundColor: tinycolor(myColor)
                .setAlpha(0.5)
                .toRgbString(),
            },
            {
              data: othersBillingsData,
              label: 'Other Doctors',
              borderColor: otherColor,
              hitRadius: 5,
              borderWidth: 2,
              spanGaps: true,
              pointBackgroundColor: otherColor,
              backgroundColor: tinycolor(otherColor)
                .setAlpha(0.5)
                .toRgbString(),
            },
          ],
          labels,
        }}
        options={{
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                type: 'time',
                distribution: 'series',
                time: {
                  minUnit: 'day',
                  stepSize: '1',
                  displayFormats: {
                    day: 'DD/MM',
                  },
                },
                gridLines: {
                  display: true,
                  drawOnChartArea: false,
                },
                ticks: {
                  display: true,
                  fontSize: 11,
                  // maxTicksLimit: 10,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  borderDash: [3, 2],
                  drawBorder: false,
                },
                ticks: {
                  // Include a dollar sign in the ticks
                  callback(value: number) {
                    return '$' + value;
                  },
                  beginAtZero: false,
                  fontSize: 11,
                  fontStyle: 600,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              title: (tooltipItem: any, data: any) => {
                const date = data.labels[tooltipItem[0].index];
                return date.format('D MMM YYYY');
              },
              label: (tooltipItem: any, data: any) => {
                const value =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                return '$' + value.toFixed(2);
              },
            },
          },
        }}
      />
    );
  }
}
