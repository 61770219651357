import moment from 'moment';
import * as React from 'react';
import { Route } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import { ISource } from '../source/ISource';
import Earnings from './Earnings';
import StatementModalManager from './StatementModalManager';

export interface IProps {
  earningsInfo: Array<{
    statementId: string;
    fromDate: string;
    toDate: string;
    netDistribution: number;
    fees: number;
    servicesIncome: number;
  }>;
  getMoreStatements: (earliestPeriodStartDateString: any) => void;
  source: ISource;
}

export interface IState {
  index: number;
  translateValue: number;
  periodStartDate: string;
  periodEndDate: string;
}

export default class EarningsPage extends React.Component<IProps, IState> {
  public initialX: null | number = null;
  public initialY: null | number = null;
  public swipeDirection: null | string = null;

  public state = {
    index: 0,
    translateValue: 0,
    periodStartDate: '',
    periodEndDate: '',
  };

  public componentDidMount = () => {
    const carouselContainer = document.getElementsByClassName(
      'carousel-container'
    )[0];

    carouselContainer.addEventListener('touchstart', this.startTouch, false);
    carouselContainer.addEventListener('touchmove', this.moveTouch, false);
    carouselContainer.addEventListener('touchend', this.endTouch, false);
  };

  public endTouch(e: Event) {
    if (this.swipeDirection === 'RIGHT') {
      const left = document.getElementById('leftArrow');
      if (!!left) {
        left.click();
      }
    } else if (this.swipeDirection === 'LEFT') {
      const right = document.getElementById('rightArrow');
      if (!!right) {
        right.click();
      }
    }
  }

  public startTouch(e: any) {
    this.initialX = e.touches[0].clientX;
    this.initialY = e.touches[0].clientY;
  }

  public moveTouch(e: any) {
    if (this.initialX === null) {
      return;
    }

    if (this.initialY === null) {
      return;
    }

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;

    const diffX = this.initialX - currentX;
    const diffY = this.initialY - currentY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      // sliding horizontally
      if (diffX > 0) {
        this.swipeDirection = 'LEFT';
      } else {
        this.swipeDirection = 'RIGHT';
      }
    } else {
      this.swipeDirection = null;
    }

    this.initialX = null;
    e.preventDefault();
  }

  public handleSlideBack = () => {
    const earningsInfo = this.props.earningsInfo;

    const isMovingToLastStatement =
      this.state.index + 1 === earningsInfo.length - 1;

    const earliestFromDate = earningsInfo[earningsInfo.length - 1].fromDate;

    if (isMovingToLastStatement) {
      this.props.getMoreStatements(earliestFromDate);
    }

    this.setState((prevState) => ({
      index: prevState.index + 1,
      translateValue: this.slideWidth() * (prevState.index + 1),
    }));
  };

  public handleSlideForward = () => {
    this.setState((prevState) => ({
      index: prevState.index - 1,
      translateValue: this.slideWidth() * (prevState.index - 1),
    }));
  };

  public slideWidth = () => {
    const carousel = document.querySelector('.carousel-item');
    return (carousel ? carousel.clientWidth : 0) + 20;
  };

  public render() {
    const navigationEnabledStyle = 'flex-2 font-bold text-base text-navy';
    const navigationDisabledStyle = 'flex-2 text-grey-dark font-bold text-base';

    const earningsInfo = this.props.earningsInfo;

    const hasData = earningsInfo.length > 0;

    let fromDate = '';
    let toDate = '';

    if (hasData) {
      fromDate = moment(earningsInfo[this.state.index].fromDate).format('ddd DD MMM');
      toDate = moment(earningsInfo[this.state.index].toDate).format('ddd DD MMM');
    }

    let periodHeader = 'Current Period';

    if (this.state.index === 1) {
      periodHeader = 'Previous Period';
    }

    const isFirstSlide = this.state.index === 0;
    const isPreviousSlide = this.state.index === 1;
    const isLastSlide = this.state.index === earningsInfo.length - 1;

    const showPeriodHeader = isFirstSlide || isPreviousSlide;

    return (
      <div className="flex flex-col w-full flex-grow">
        <AppHeader title="Earnings" />

        {/* Left / Right navigation */}
        <div className="my-2 px-6 flex flex-no-shrink">
          <button
            id="leftArrow"
            disabled={isLastSlide}
            className={
              isLastSlide ? navigationDisabledStyle : navigationEnabledStyle
            }
            onClick={this.handleSlideBack}
          >
            &larr;
          </button>
          <div className="flex flex-col flex-1 justify-center" style={{minHeight: '2.25rem'}}>
            { showPeriodHeader ?
              <div className={'text-navy font-bold text-base text-center mb-1'}>{periodHeader}</div> : 
              null }
            <div className="text-center text-xs text-normal">{fromDate} - {toDate}</div>
          </div>
          <button
            id="rightArrow"
            disabled={isFirstSlide}
            className={
              isFirstSlide ? navigationDisabledStyle : navigationEnabledStyle
            }
            onClick={this.handleSlideForward}
          >
            &rarr;
          </button>
        </div>

        <div className="overflow-x-hidden flex-grow">
          <div style={{
                transform: `translateX(${this.state.translateValue}px)`,
                transition: 'transform ease-out 0.45s'
              }} className="carousel-container flex flex-row-reverse pr-5">
              {earningsInfo.map((x, i) => {
                return <Earnings key={i} earningsInfo={x} />
              })}
          </div>
        </div>

        <Route
          path="/earnings/statement/:id"
          render={() => <StatementModalManager source={this.props.source} />}
        />
      </div>
    );
  }
}
